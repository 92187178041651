import {useEffect} from 'react'
import { navigate } from 'gatsby'
import { getBrowserLanguage } from '@/utils'

const IndexPage = () => {
  useEffect(() => {
    navigate(`/${getBrowserLanguage()}`)
  }, [])
  return null
}

export default IndexPage
