const slugify = require('slugify')

exports.sluggy = (str) => {
  return slugify(str, { lower: true })
}

exports.getBrowserLanguage = () => {
  if (typeof navigator === `undefined`) return 'en'
  const lang = navigator && navigator.language && navigator.language.split('-')[0]
  if (!lang) return 'en'
  switch (lang) {
    case 'fr':
      return 'fr'
    default:
      return 'en'
  }
}

exports.getLocationLanguage = () => {
  if (typeof window === `undefined`) return 'en'
  const lang = window.location.pathname.toLowerCase().split('/')[1]
  if (!lang) return 'en'
  return lang
}

exports.getLocationWithoutLang = () => {
  if (typeof window === `undefined`) return []
  const array = window.location.pathname.toLowerCase().split('/')
  array.splice(0, 2)
  return array.length === 1 && array[0].length <= 0 ? [] : array
}

exports.getSources = (parent) => {
  const mobileFluid = (parent.mobile && parent.mobile.fluid) ? parent.mobile.fluid : parent.desktop.fluid
  return [
    mobileFluid,
    {
      ...parent.desktop.fluid,
      media: `(min-width: 1024px)`,
    },
  ]
}